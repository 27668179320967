

import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    scroll: 0,
  })

const getters = {
  getField
}

const mutations = {
  updateField
}

export default {
    state,
    namespaced:true,
    getters,
    mutations,
}