
import { mapFields } from 'vuex-map-fields';
import AppBtnNew from '@/components/AppBtnNew.vue'

export default {    
    'name':'AppBar',

    components: {
      AppBtnNew,
    },

    computed:{
        ...mapFields('scroll',['scroll']),
        ...mapFields('menu',['menu','drawer']),
    },
    methods:{
      async goTo(elem) {
        if(elem.href){
          return
        }
        if (!elem.goTo && process.client) {
          window.location.href = elem.path;
        }
        else {
          if (this.$router.currentRoute.path != elem.path) {
            await new Promise((resolve, reject) => {
              this.$router.push(elem.path, resolve, reject)
            })
          }
          this.$vuetify.goTo(elem.goTo);
        }
      }
    }
}
