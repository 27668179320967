import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    menu:[
      {name:'Inicio', path: '/', hash:'#hero', onlyDrawer: true},
      {name:'¿Cómo funciona?', path: '/como-funciona/', onlyDrawer: false},
      {name:'Compatibilidad', path: '/compatibilidad/', onlyDrawer: false},
      {name:'Tarifas', path: '/tarifas/', onlyDrawer: false},
      {name:'Bancos', path: '/bancos/', onlyDrawer: false},
      /* {name: 'Casos de éxito', path: '/casos-de-exito/'}, */
      {name:'Recursos', path: '/recursos/', onlyDrawer: false, dropdown: true, 
        submenu: [          
          {name: 'Blog', path:'/blog/'},
          {name: 'Casos de éxito', path:'/casos-de-exito/'},
          {name: 'Webinar', path: '/webinar/como-reducir-a-la-mitad-el-proceso-contable-de-tu-cierre-trimestral/', onlyDrawer: false},
          {name: 'Partners', path: '/partners/'},
        ]
      },            
      {name: 'Kit digital', path: '/kit-digital/', onlyDrawer: false},
      {name: 'Contacto', path: '/contacto/',onlyDrawer: false},
      {name: 'Ayuda', href: 'https://ayuda.inmatic.ai/es/', target: '_blank', onlyDrawer: true},
      {name:'Área clientes', href: 'https://app.inmatic.ai', hash: false, onlyDrawer: true},
    ],
    drawer:false,
  })

const getters = {
  getField
}

const mutations = {
  updateField
}

export default {
    state,
    namespaced:true,
    getters,
    mutations,
}
