
import { mdiClose } from '@mdi/js';

export default {
    name:'LegalDialog',
    props:{
        title:String,
        value:Boolean,
    },
    data(){
        return {
            mdiClose,
            myValue:false
        }
    },
    created(){
        this.myValue=this.value
    },
    methods:{
        emitChange(val){
            this.myValue=val
            this.$emit('input',val)
        }
    },
    watch:{
        value(val){
            this.myValue=val
        }
    }
}
