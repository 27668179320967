
  import AppFullSizeCard from '@/components/AppFullSizeCard.vue'
  import { mdiChevronTripleRight } from '@mdi/js';

  export default {
    components: {
      AppFullSizeCard,
    },
    data() {
      return {
        mdiChevronTripleRight
      }
    },
    props: ['error'],
    layout: 'error', // you can set a custom layout for the error page
    head: {
      title: 'Inmatic - Página no encontrada',
    }
  }
