
export default {
  name: 'AppBtnNew',

  props: {   
    lightColor: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false
    },
    navbar: {
      type: Boolean,
      default: false,
    },

    block: {
      type: Boolean,
      default: false,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    elevation: {
      type: [String, Number],
      default: 0,
    }
  },

  computed: {
    color() {
      return this.lightColor ? '#1BBFF7' : 'primary'
    },

    computedClass() {
      let classes = 'text-center px-3 white--text';
      if (this.bold) {
        classes+= ' font-weight-bold';
      }
      return classes
    }
  }
}
