export default () => {
  if (process.client) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "9c32fa46-a3c0-4f86-83b3-2fba604fc061";

      (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }
};