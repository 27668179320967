
import LegalWarningSection from "./LegalWarningSection.vue";
import { mapState } from "vuex";

export default {
    name:'LegalWarning',
    components:{
        LegalWarningSection,
    },
    computed:{
        ...mapState("legal",["fullName","shortName","registerData","target","webs","product","contactMail","formToUnsubscribe","address","vatCode"])
    }
}
