
import { mapState } from "vuex";
export default {
  name: 'CookiesPolicy',
  components: {
  },
  computed:{
    ...mapState("legal",["fullName","shortName","registerData","target","webs","product","contactMail","formToUnsubscribe","address","cookies"])
  }
}
