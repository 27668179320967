
import { mapFields } from 'vuex-map-fields';
import AppBtnNew from '@/components/AppBtnNew'
import { mdiLightbulbOutline, mdiAccountOutline, mdiMenu, mdiChevronDown } from '@mdi/js';

const routesWithLightHero = [
'tarifas',
'kit-digital',
'legal-aviso-legal',
'legal-politica-de-privacidad',
'legal-politica-de-cookies',
'legal-condiciones-de-venta',
]
const routesResources = [
  'blog',
  'casos-de-exito',
  'recursos'
]

export default {
  'name':'AppBarNew',

  components: {
    AppBtnNew
  },  

  methods: {
    async goTo(elem) {
      if(elem.href){
        return
      }
      if (!elem.goTo && process.client) {
        window.location.href = elem.path;
      }
      else {
        if (this.$router.currentRoute.path != elem.path) {
          await new Promise((resolve, reject) => {
            this.$router.push(elem.path, resolve, reject)
          })
        }
        this.$vuetify.goTo(elem.goTo);
      }
    },

    navbarElmClass(elm) {
      let classes = ''
      /*const elmPositionIsNotLast = this.menu.indexOf(elm) < this.menu.length - 1*/
      
      if (elm.name != "Contacto") {
        classes = 'navbar__elm__has-bar '
      } else {
        classes = 'mr-2 '
      }
      if ((elm.name == "Recursos") && routesResources.includes(this.$route.name)){
        classes += 'navbar__elm__active ';
      }
 
      if (this.isScrolledDown || routesWithLightHero.includes(this.$route.name)) {
        return classes + 'navbar__elm-scrolled-down'
      }
      return classes + 'navbar__elm-no-scroll'
    }
  },
  computed:{
      ...mapFields('scroll',['scroll']),
      ...mapFields('menu',['menu','drawer']),
      isScrolledDown() {
        return this.scroll>this.threshold
      },

      flatBar(){
          return this.scroll===0
      },
      barColor(){
        if (this.isScrolledDown || routesWithLightHero.includes(this.$route.name)) {
          return "primary"
        }
        return "rgba(255,255,255,0.0)"
      },      
      logo(){        
        return '/logo_blanc.png'
      },     
  },
  data(){
    return {
      threshold:50,
      mdiAccountOutline,
      mdiLightbulbOutline,
      mdiMenu,
      mdiChevronDown
    }
  }
}
