
import { client } from "@/api/client";

export default {
  name: "LegalContract",
  props: {
    params: {
      type: Object,
      required: false,
      default: () => ({})
    },
    kind: {
      type: String,
      required: false,
      default: ""
    },
    api: {
      type: String,
      required: false,
      default: "get_latest_privacy_policy_contract"
    },
  },
  data() {
    return {
      content: undefined,
      loading: false
    }
  },
  async mounted() {
    this.loading = true
    let response = await client.get('api/'+ this.api + '/' + this.kind)
    this.content = response.data.content;

    Object.entries(this.params).forEach(element => {
      this.content = this.content.replaceAll("{{ " + element[0] + " }}", element[1])
    });
    this.loading = false
  }
};
