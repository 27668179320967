import { render, staticRenderFns } from "./FooterNew.vue?vue&type=template&id=3c0f5146&"
import script from "./FooterNew.vue?vue&type=script&lang=js&"
export * from "./FooterNew.vue?vue&type=script&lang=js&"
import style0 from "./FooterNew.vue?vue&type=style&index=0&id=3c0f5146&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtnStyle3: require('/home/tonib/dev/web-inmatic/nuxt/components/AppBtnStyle3.vue').default})
