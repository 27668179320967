import { leads as api_leads } from "@/api";

// Types
export const ADD_LEAD_BEGIN = "ADD_LEAD_BEGIN";
export const ADD_LEAD_ERROR = "ADD_LEAD_ERROR";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const ADD_HOW_MEET_US_ITEMS = "ADD_HOW_MEET_US_ITEMS";
export const ADD_PROGRAM_ITEMS = "ADD_PROGRAM_ITEMS";
export const RESET_STATE = "RESET_STATE"

// initial state
// shape: [{ code, name }]
const state = () => ({
  error: false,
  loading: false,
  success: false,
  how_meet_us_items: [],
  programs_items: [],
})

// getters
const getters = {
    loading: state => state.loading,
    error: state => state.error,
    success: state => state.success,
    how_meet_us_items: state => state.how_meet_us_items,
    programs_items: state => state.programs_items,
}

// actions
const checkLeadData = ({ commit }, data) => {
  let is_correct = true;
  let email_re = /^[\w-.]+@([\w-]+\.)+[\w]{2,3}$/;
  let errors = {};
  if (!email_re.test(data.email)) {
    is_correct = false;
    errors['email'] = true;
  }
  let phone_re = /^(\+[0-9]{2})?[0-9]{9}$/;
  if (!phone_re.test(data.phone)) {
    is_correct = false;
    errors['phone'] = true;
  }
  if (!data.first_name) {
    is_correct = false;
    errors['first_name'] = true;
  }
  if (!data.last_name) {
    is_correct = false;
    errors['last_name'] = true;
  }
  if (!data.company) {
    is_correct = false;
    errors['company'] = true;
  }
  if (!data.programs) {
    is_correct = false;
    errors['programs'] = true;
  }
  if (!data.how_meet_us) {
    is_correct = false;
    errors['how_meet_us'] = true;
  }
  if (Object.keys(errors).length) {
    commit(ADD_LEAD_ERROR, errors);
  }
  return is_correct;
};

const addLead = async ({ commit }, data) => {
  commit(ADD_LEAD_BEGIN);
  try {
    const response = await api_leads.addLead(data)
    if(Object.keys(response.data).includes('lead')){
      commit(ADD_LEAD_SUCCESS);
      /* window.fbq('track', 'Contact');
      window.gtag('event', 'conversion', {'send_to': 'AW-473227358/hD54COXt64IDEN7A0-EB'}); */
      //window.gtag_report_conversion();
    } 
    else {
      commit(ADD_LEAD_ERROR, response.data);
    } 
    return response;
  } catch (error) {
    // TODO: Properly handle call errors
    commit(ADD_LEAD_ERROR, {'server_error':true});
    throw error;
  }
};

const getHowMeetUSChoices = async ({ commit }, data) => {
  const response = await api_leads.getHowMeetUSChoices(data)
  commit(ADD_HOW_MEET_US_ITEMS, response.data['choices'])
};

const getProgramChoices = async ({ commit }, data) => {
  const response = await api_leads.getProgramChoices(data);
  commit(ADD_PROGRAM_ITEMS, response.data['choices']);
}

const actions = {
  addLead,
  checkLeadData,
  getHowMeetUSChoices,
  getProgramChoices,
}

// mutations
const mutations = {
  [ADD_LEAD_BEGIN](state) {
    state.success = false;
    state.loading = true;
    state.error = false;
  },
  [ADD_LEAD_SUCCESS](state) {
    state.success = true;
    state.error = false;
    state.loading = false;
  },
  [ADD_LEAD_ERROR](state, data) {
    state.success = false;
    state.error = data;
    state.loading = false;
  },
  [ADD_HOW_MEET_US_ITEMS](state, items) {
    state.how_meet_us_items = items;
  },
  [ADD_PROGRAM_ITEMS](state, items) {
    state.programs_items = items;
  },
  [RESET_STATE](state) {
    state.success = false;
    state.loading = false;
    state.error = false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}