

import { mapFields } from 'vuex-map-fields'

import ScrollHandler from "@/components/ScrollHandler.vue"
import FooterNew from "@/components/FooterNew.vue"
import AppBarNew from "@/components/AppBarNew.vue"
import Drawer from "@/components/Drawer.vue"
import CookieConsent from 'vue-cookieconsent-component'


export default {
  name: 'App',
  components: {
    CookieConsent,
    ScrollHandler,
    AppBarNew,
    Drawer,
    FooterNew,
  },
  methods:{
    privacyPolicy(){
    }
  },
  data(){
    return {
      legalWarningDialog:false,
      saleTerms: false,
    }
  },
  computed:{
    ...mapFields('legal',['showPrivacyPolicy', 'showCookiePolicy']),
    shouldShowBar() {
      return this.$route.path.indexOf('webinarxyz5567')==-1 && this.$route.path.indexOf('/webinar/directo')==-1 && this.$route.path.indexOf('/webinar/bancos')==-1 && this.$route.path.indexOf('/webinar/pildoras')==-1;
    }
  },

  mounted() {
    console.log(window)
     if (process.client) {
       window.$crisp.push(["config", "color:theme", 'light_blue'])
     }
  }
};
