
import CookiesPolicy from "@/components/LegalContent/CookiesPolicy.vue"
import SaleTerms from "@/components/LegalContent/SaleTerms.vue"
import LegalDialog from "@/components/LegalContent/LegalDialog.vue"
import LegalWarning from "@/components/LegalContent/LegalWarning.vue"
import LegalContract from "@/components/LegalContent/LegalContract.vue"
import { mapFields } from 'vuex-map-fields';
import AppBtnStyle3 from '@/components/AppBtnStyle3.vue'
import { mdiPhoneSettingsOutline, mdiEmailArrowRightOutline} from '@mdi/js'; 

export default {
name: 'Footer',
components: {
  LegalContract,
  CookiesPolicy,
  LegalDialog,
  LegalWarning,  
  SaleTerms,
  AppBtnStyle3,  
},
methods:{
},
data(){
  return {
    legalWarningDialog:false,
    saleTerms: false,
    mdiPhoneSettingsOutline,
    mdiEmailArrowRightOutline,
    menu: {
      inmatic: [
        { name: 'Tarifas', path: '/tarifas/' },
        { name: 'Prueba gratis', href: 'https://app.inmatic.ai/signup' },
        { name: 'Blog', path: '/blog/'},
        { name: 'Casos de éxito', path: '/casos-de-exito/'},
        { name: 'Inteligencia artificial en la contabilidad', path: '/blog/inteligencia-artificial-contabilidad/' },        
      ],
      servicios: [
        { name: 'Contabilización automática de facturas', path: '/', hash:"#hero" },
        { name: 'Conciliación bancaria',  path: '/bancos/'},
        { name: 'Programa Kit Digital', path: '/kit-digital/'},
        { name: 'Compatibilidad', path: '/compatibilidad/'},
        { name: 'Programa de Partner', path: '/partners/'},
        { name: 'Perfil de producto', path: '/como-funciona/'},
      ],
      ayuda: [
        { name: 'Centro de ayuda', href: 'https://ayuda.inmatic.ai/es/', target: '_blank', rel: 'nofollow noopener' },
        { name: 'Habla con un experto', call_to_action: "openChat" },        
      ] ,
      area_cliente: [
        { name: 'Iniciar sesión', href: 'https://app.inmatic.ai/login' },
        { name: 'Crear cuenta', href: 'https://app.inmatic.ai/signup' },      
      ],
      legal: [
        { name: 'Aviso Legal', path: '/legal/aviso-legal/', rel: 'nofollow' },
        { name: 'Condiciones de venta', path: '/legal/condiciones-de-venta/', rel: 'nofollow' },
        { name: 'Política de Privacidad', path: '/legal/politica-de-privacidad/', rel: 'nofollow' },
        { name: 'Política de Cookies', path: '/legal/politica-de-cookies/', rel: 'nofollow' },        
      ]      
    }
  }
},
methods: {
  openChat() {
    if (process.client) {          
      window.$crisp.push(["do", "chat:open"]);         
    }
  }
},
computed:{
  ...mapFields('legal',['showPrivacyPolicy', 'showCookiePolicy']),
 
  footerHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': 
        return '1700'
      case 'sm':
        return '1050'
      case 'md':
        return '600'        
      case 'lg':      
      case 'xl':
      default:
        return '520'
    }
  },

  footerJustify() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
      case 'md':
        return 'justify-space-around'
      case 'lg':
      case 'xl':
      default:
        return 'justify-center'
    }
  },
  
}
}
