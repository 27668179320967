import axios from "axios";

// const CSRF_COOKIE_NAME = "csrftoken";
// const CSRF_HEADER_NAME = "X-CSRFToken";

const baseURL = "https://api.inmatic.ai";
// const baseURL = "http://localhost:8896/";
//const baseURL = "http://despatx.coneptum.com:8896";

let client = axios.create({
    baseURL,
    // xsrfCookieName: CSRF_COOKIE_NAME,
    // xsrfHeaderName: CSRF_HEADER_NAME
  });

export {client, };
