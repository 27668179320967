import { render, staticRenderFns } from "./error.vue?vue&type=template&id=a1780c1e&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=a1780c1e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1780c1e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFullSizeCard: require('/home/tonib/dev/web-inmatic/nuxt/components/AppFullSizeCard.vue').default})
