
import debounce from 'lodash/debounce';
import { mapFields } from 'vuex-map-fields';


export default {
  created () {
    this.handleDebouncedScroll = debounce(this.handleScroll, 10);
    if (process.client) { 
      window.addEventListener('scroll', this.handleDebouncedScroll);
    }
  },
  beforeDestroy () {
    if (process.client) {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
  },
  methods: {
    handleScroll() {
      if (process.client) {
        this.scroll=window.scrollY
      }
    }
  },
  computed: {
      ...mapFields('scroll',['scroll']),
  }
}
