import Vuex from 'vuex'
import Vue from 'vue'
import leads from "./modules/leads"
import legal from "./modules/legal"
import scroll from "./modules/scroll"
import menu from "./modules/menu"


Vue.use(Vuex)
export default () => new Vuex.Store({
  modules: {
    leads,
    legal,
    scroll,
    menu,
  },
  state: {
  },

  mutations: {
  },

  actions: {
  }
});
