
export default {
  name: 'AppFullSizeCard',

  props: {
    color: {
      type: String,
      required: false,
    },

    halfViewport: {
      type: Boolean,
      default: false
    },

    tag: {
      type: String,
      default: 'div',
    }
  }
}
