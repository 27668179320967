

export default {
  name: 'AppBtnStyle3',

  props: {      
    hrefLink: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    },            
  },
  data() {
    return {    
    }
  },
  computed: {
  }
}
