import { render, staticRenderFns } from "./LegalWarning.vue?vue&type=template&id=49222754&scoped=true&"
import script from "./LegalWarning.vue?vue&type=script&lang=js&"
export * from "./LegalWarning.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49222754",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LegalWarningSection: require('/home/tonib/dev/web-inmatic/nuxt/components/LegalContent/LegalWarningSection.vue').default})
