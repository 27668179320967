import { client } from "./client";

const ROUTES = {
  addLead: () => `/api/create_lead_by_mail/`,
  getHowMeetUSChoices: () => `/api_crm/get_how_meet_us_choices/`,
  getProgramChoices: () => `/api_crm/get_program_choices/`,
};

export const leads = {
  addLead: (data) => {
    return client.post(ROUTES.addLead(),data);
  },
  getHowMeetUSChoices: (data) => {
    return client.get(ROUTES.getHowMeetUSChoices(),data);
  },
  getProgramChoices: (data) => {
    return client.get(ROUTES.getProgramChoices(),data);
  },
};
